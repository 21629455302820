import { createStore } from 'vuex'

export default createStore({
  state: {
    api_url : 'https://naija-jollof-server.eminentinfluencer.com/'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
